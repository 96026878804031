import { useEffect } from 'react';
import { string as zString } from 'zod';
import {
  init as initDDRum,
  initializeLogger,
  type DataDogRum,
  setUser,
  setExperiments,
} from '@hungryroot/o11y';
import { datadogRum } from '@datadog/browser-rum';
import { useIdentify } from '@hungryroot/customer';
import { useCustomerExperiments } from '@hungryroot/experiments';
import { islandRoot } from '../IslandRoot';

const DATADOG_RUM_KEY = zString().parse(process.env.PUBLIC_DATADOG_RUM_KEY);
const DATADOG_APPLICATION_ID = zString().parse(
  process.env.PUBLIC_DATADOG_RUM_APP_ID
);
const DATADOG_RUM_SAMPLE_RATE = zString().parse(
  process.env.PUBLIC_DATADOG_RUM_SAMPLE_RATE
);

export const O11yIsland = islandRoot(O11y);

export function O11y() {
  const { customerData } = useIdentify();
  const { data: experimentData } = useCustomerExperiments();

  useEffect(() => {
    datadogRum.init({
      applicationId: DATADOG_APPLICATION_ID,
      clientToken: DATADOG_RUM_KEY,
      site: 'datadoghq.com',
      service: 'hungryroot-web',
      sessionSampleRate: parseInt(DATADOG_RUM_SAMPLE_RATE, 10),
      sessionReplaySampleRate: 0,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
    });

    initDDRum(datadogRum as DataDogRum);
    initializeLogger();
  }, []);

  useEffect(() => {
    if (customerData === undefined) {
      return;
    }

    setUser({
      id: customerData.id.toString(),
      name:
        customerData.first_name === undefined ? '' : customerData.first_name,
      email: customerData.email,
    });
  }, [customerData]);

  useEffect(() => {
    if (experimentData === undefined) {
      return;
    }

    setExperiments(
      experimentData.experiments.map(({ experiment: id, variant }) => {
        return {
          id,
          variant,
        };
      })
    );
  }, [experimentData]);

  return null;
}
